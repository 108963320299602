import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import ImageContainer from '../../../components/image-container';
import SectionContainerLayoutWithFilter from '../../../components/section-container-layout-with-filter';

export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan Your Trip' />
      <SectionContainerLayoutWithFilter isViewAll title='PLAN YOUR TRIP' baseLink='/press/plan-your-trip' isDivider>
        <Grid container>
          <Box position='relative' clone>
            <Grid item xs={12}>
              <ImageContainer filename='press-and-media-information-press-2' altText='plan-your-trip' />
              <Box
                position='absolute'
                left='0'
                style={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0 , 0 , 0 , 0.5)',
                }}>
                <Box p={2}>
                  <Typography variant='h3' style={{ fontWeight: 'bold' }}>
                    Press and Media Information
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box p={2} height='100%'>
              <Typography variant='h6'>
                <br />
                Please be ready to present a printed COVID-19 vaccination certificate or a negative PCR result (48
                hours). This is a mandatory precaution for all visitors. Please remember to have proof of Expo 2020
                media accreditation and your print-at-home badge to gain access to GMIS 2021. We would recommend media
                attending GMIS 2021 arrive early to avoid queues by the door.
                <br />
                <br />
                For media, the vaccine status will be validated during the accreditation process. Media will need to
                have had both doses of the vaccine no later than 1st October 2021 to qualify for accreditation.
                <br />
                <br /> For media inquiry, please contact:
                <Link href='mailto:press@gmisummit.com'> press@gmisummit.com</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
